// import packages
import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import classnames from "classnames";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";

import Breadcrumb from "../breadcrumb/Breadcrumb";

import { isAuthenticated } from "../../../auth/auth";

function BankTransferCom(props) {
  const [amount, setAmount] = useState(
    sessionStorage.getItem("payment_amt")
      ? sessionStorage.getItem("payment_amt")
      : ""
  );
  const [amountErr, setAmountErr] = useState("");
  const [imageId, setImageId] = useState("");
  const [imageIdErr, setImageIdErr] = useState("");
  const [slip, setSlip] = useState("");
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setAmount(e.target.value);
    setAmountErr("");
  };

  const handlebankSlip = (e) => {
    let data = e.target.files[0];
    setSlip(data);

    var bodyFormData = new FormData();
    bodyFormData.append("file", e.target.files[0]);

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/v1/generic/upload`,
        bodyFormData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        setImageId(res.data.name);

        // clear value
        document.getElementById("imgUpload").value = "";
      })
      .catch((err) => console.log(err));
  };

  // validate
  const validate = () => {
    let amountErr = "";
    let imageIdErr = "";

    if (!amount) {
      amountErr = "Amount is required";
    }

    if (!imageId) {
      imageIdErr = "Image is required";
    }

    if (amountErr || imageIdErr) {
      setAmountErr(amountErr);
      setImageIdErr(imageIdErr);

      return false;
    }

    return true;
  };

  const handleUpload = () => {
    if (validate()) {
      setLoading(true);
      let data = `amount=${amount}&imageId=${imageId}`;

      axios
        .post(
          `${process.env.REACT_APP_API_URL}/v1/agent_payments/scanned`,
          data,
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              Authorization: `Bearer ${isAuthenticated()}`,
            },
          }
        )
        .then((res) => {
          NotificationManager.success("Uploaded successfully!", "Success");

          setLoading(false);
          sessionStorage.removeItem("payment_amt");
          props.history.push("/dashboard/payments");
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  };

  return (
    <>
      <div className="content-col">
        <Breadcrumb title="Select Payment Method" />

        <div className="container-fluid inner-content">
          <div className="ad-form-sec">
            <form noValidate>
              <div className="form-row header">
                <div className="form-group col px-sm-2 mb-0">
                  <p className="heading mb-0">
                    Please deposit/transfer the due amount to the bank account
                    mentioned below and upload the slip with amount.
                  </p>
                </div>
              </div>

              <div className="content" style={{ paddingTop: 10 }}>
                <div className="slip-upload-div">
                  <form noValidate>
                    <div className="input-group col p-0 mb-3">
                      <label htmlFor="amount">Paid Amount</label>
                      <div className="input-group">
                        <input
                          type="number"
                          className={classnames("form-control", {
                            "is-invalid": amountErr,
                          })}
                          id="amount"
                          name="amount"
                          onChange={handleChange}
                          value={amount}
                        />
                      </div>
                      <p className="cus-error">{amountErr}</p>
                    </div>

                    <label className="d-block" htmlFor="amount">
                      Bank Slip Photo
                    </label>

                    {slip ? (
                      <img
                        src={URL.createObjectURL(slip)}
                        className="prev-images"
                        alt="images"
                        style={{
                          width: "100%",
                          height: "130px",
                          objectFit: "cover",
                        }}
                      />
                    ) : (
                      <>
                        {" "}
                        <input
                          type="file"
                          id="imgUpload"
                          className="imgUpload"
                          name="photo"
                          onChange={handlebankSlip}
                          accept="image/x-png,image/jpeg,image/jpg"
                        />
                        <label
                          className="img-upload-label"
                          htmlFor="imgUpload"
                          style={{ width: "100%", height: "130px", margin: 0 }}
                        >
                          {props.loading ? "" : ""}
                          <i className="fas fa-plus add-img-icon"></i>
                        </label>
                      </>
                    )}

                    <p
                      style={{
                        fontSize: 12,
                        color: "#dc3545",
                        marginTop: "5px",
                        marginLeft: "5px",
                      }}
                    >
                      {imageIdErr}
                    </p>

                    <button
                      type="button"
                      className="btn-submit mt-0"
                      onClick={handleUpload}
                      style={{ width: 195, margin: "auto" }}
                    >
                      {loading ? (
                        <div
                          className="spinner-border spinner-border-sm"
                          role="status"
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                      ) : (
                        "Upload"
                      )}
                    </button>
                  </form>
                </div>

                <hr />
                <p align="center" style={{ marginBottom: 10, fontWeight: 500 }}>
                  Bank Account
                </p>
                <p className="bank-info">
                  <b>Acc Name :</b> Serendib Systems Lanka Pvt Ltd
                </p>
                <p className="bank-info">
                  <b>Acc No :</b> 017510008154
                </p>

                <p className="bank-info">
                  <b>Bank :</b> Sampath Bank PLC (7278)
                </p>

                <p className="bank-info mb-4">
                  <b>Branch :</b> Colombo Super (175)
                </p>
              </div>

              <button
                type="button"
                className="btn-submit mt-3"
                onClick={() => props.history.goBack()}
                style={{
                  width: "auto",
                  float: "left",
                  background: "#efefef",
                }}
              >
                <i className="fas fa-angle-left"></i> Back
              </button>
            </form>
          </div>
        </div>
      </div>

      <NotificationContainer />
    </>
  );
}

export default withRouter(BankTransferCom);
