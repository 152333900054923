import React, { Component } from "react";

import ForgetPasswordCom from "../components/visitor/forget-password/ForgetPasswordCom";

import "../sass/style.css";

export default class ForgetPassword extends Component {
  render() {
    return <ForgetPasswordCom />;
  }
}
