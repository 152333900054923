import React from "react";

import AdminLayout from "../../components/admin/layouts/AdminLayout";
import ManageAllAds from "../../components/admin/all-ads/ManageAllAds";

function AllAds() {
  return (
    <AdminLayout>
      <ManageAllAds />
    </AdminLayout>
  );
}

export default AllAds;
