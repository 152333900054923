import React, { useState, useEffect } from "react";
import axios from "axios";

import Close from "../../../images/close-white.png";

import { isAuthenticated } from "../../../auth/auth";

function InvoiceInfoModal(props) {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    if (props.invId) {
      // invoice
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/v1/agent_invoices/${props.invId}`,
          {
            headers: {
              Authorization: `Bearer ${isAuthenticated()}`,
            },
          }
        )
        .then((res) => {
          console.log(res.data.items);
          setPosts(res.data.items);
        })
        .catch((err) => {
          console.log(err);
          // setLoading(false);
        });
    }
  }, [props.invId]);

  return (
    <div
      className="modal fade"
      id="invoiceInfoModal"
      tabIndex="-1"
      aria-labelledby="invoiceInfoModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <p className="modal-title">Payment Breakdown</p>
            <img
              src={Close}
              alt="close"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              width="50px"
              style={{ cursor: "pointer" }}
            />
          </div>
          <div className="modal-body">
            <table
              className="table table-hover table-responsive  common-table border mb-0"
              style={{ fontSize: 14 }}
            >
              <thead className="header">
                <tr>
                  <th className="border-0">Ad ID</th>
                  <th className="border-0">Ad Price</th>
                  <th className="border-0">Commission</th>
                </tr>
              </thead>
              <tbody>
                {posts.length &&
                  posts.map((el, i) => (
                    <tr key={i}>
                      <td width="200">
                        <p style={{ marginBottom: 0 }}>{el.postId}</p>
                      </td>
                      <td width="200">
                        <p style={{ marginBottom: 0 }}>Rs. {el.amount}</p>
                      </td>
                      <td align="right">
                        <p style={{ marginBottom: 0 }}>Rs. {el.commission}</p>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InvoiceInfoModal;
