import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import moment from "moment";

import Breadcrumb from "../breadcrumb/Breadcrumb";

import { isAuthenticated } from "../../../auth/auth";
import InvoiceInfoModal from "../modals/InvoiceInfoModal";

function ManageInvoices(props) {
  const [curInvoice, setCurInvoice] = useState([]);
  const [prevInvoices, setPrevInvoices] = useState([]);
  const [invId, setInvId] = useState("");

  useEffect(() => {
    // remove payment_amt
    sessionStorage.removeItem("payment_amt");

    axios
      .get(`${process.env.REACT_APP_API_URL}/v1/agent_invoices`, {
        headers: {
          Authorization: `Bearer ${isAuthenticated()}`,
        },
      })
      .then((res) => {
        // setLoading(false);
        if (res.data.length > 0) {
          let sortByDate = res.data.sort((a, b) =>
            b.dateTo.localeCompare(a.dateTo)
          );

          axios
            .get(`${process.env.REACT_APP_API_URL}/v1/agent_payments`, {
              headers: {
                Authorization: `Bearer ${isAuthenticated()}`,
              },
            })
            .then((res) => {
              const filterByInvId = res.data.filter(
                (el) => el.invoiceId === sortByDate[0].id
              );

              if (filterByInvId.length > 0) {
                const totPaid = filterByInvId.reduce(
                  (currentValue, nextValue) => {
                    return currentValue + nextValue.amount;
                  },
                  0
                );

                Object.assign(sortByDate[0], {
                  paidAmount: totPaid,
                });
              } else {
                Object.assign(sortByDate[0], {
                  paidAmount: 0,
                });
              }

              setCurInvoice([...curInvoice, sortByDate[0]]);
            })
            .catch((err) => {
              console.log(err);
            });

          const prevInvs = sortByDate.slice(1);
          setPrevInvoices(prevInvs);
        }
      })
      .catch((err) => {
        console.log(err);
        // setLoading(false);
      });
  }, []);

  const handleInvoice = (id) => {
    setInvId(id);
  };

  return (
    <React.Fragment>
      <div className="content-col">
        <Breadcrumb title="Invoices" />

        <div className="container-fluid inner-content py-4">
          <div className="ad-form-sec" style={{ maxWidth: "fit-content" }}>
            <p className="invoice-sec-heading">Current Invoice</p>
            <div className="content p-0 mb-4">
              <table
                className="table table-hover table-responsive common-table border mb-0"
                style={{ fontSize: 14 }}
              >
                <thead className="header">
                  <tr>
                    <th className="border-0">Invoice ID</th>
                    <th className="border-0">From</th>
                    <th className="border-0">To</th>
                    <th className="border-0 text-right">Total Amount</th>
                    <th className="border-0 text-right">Paid</th>
                    <th className="border-0 text-right">Due</th>
                    <th className="border-0 text-center">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {curInvoice.length > 0 ? (
                    curInvoice.map((el) => (
                      <tr key={el.id}>
                        <td>
                          <p style={{ marginBottom: 0 }}>{el.id}</p>
                        </td>
                        <td>
                          {moment.utc(el.dateFrom).local().format("DD/MM/YYYY")}
                        </td>
                        <td>
                          {moment.utc(el.dateTo).local().format("DD/MM/YYYY")}
                        </td>
                        <td className="text-right">
                          <p style={{ marginBottom: 0 }}>
                            Rs. {el.amount + el.carriedForwardAmount}
                          </p>
                          <p
                            className="mb-0 text-primary text-right"
                            data-toggle="modal"
                            data-target="#invoiceInfoModal"
                            onClick={() => handleInvoice(el.id)}
                            style={{ cursor: "pointer" }}
                          >
                            More info
                          </p>
                        </td>
                        <td className="text-right">
                          <p style={{ marginBottom: 0 }}>Rs. {el.paidAmount}</p>
                        </td>
                        <td className="text-right">
                          <p style={{ marginBottom: 0 }}>
                            {el.amount +
                              el.carriedForwardAmount -
                              el.paidAmount <
                            0
                              ? `Cr Rs. ${
                                  el.amount +
                                  el.carriedForwardAmount -
                                  el.paidAmount
                                }`
                              : `Rs. ${
                                  el.amount +
                                  el.carriedForwardAmount -
                                  el.paidAmount
                                }`}
                          </p>
                        </td>

                        <td style={{ width: 100 }}>
                          <button
                            className="btn btn-success btn-sm px-4"
                            onClick={() =>
                              props.history.push(
                                `/dashboard/select-payment-method?invId=${el.id}`
                              )
                            }
                          >
                            Pay
                          </button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="3" className="text-muted">
                        No Recods
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>

            <p className="invoice-sec-heading">Previous Invoices</p>
            <div className="content p-0" style={{ maxWidth: "fit-content" }}>
              <table
                className="table table-hover table-responsive common-table border mb-0"
                style={{ fontSize: 14 }}
              >
                <thead className="header">
                  <tr>
                    <th className="border-0">Invoice ID</th>
                    <th className="border-0">From</th>
                    <th className="border-0">To</th>
                    <th className="border-0 text-right">Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {prevInvoices.length ? (
                    prevInvoices.map((el) => (
                      <tr key={el.id}>
                        <td width="170">
                          <p style={{ marginBottom: 0 }}>{el.id}</p>
                        </td>
                        <td width="170">
                          {moment.utc(el.dateFrom).local().format("DD/MM/YYYY")}
                        </td>
                        <td width="170">
                          {moment.utc(el.dateTo).local().format("DD/MM/YYYY")}
                        </td>
                        <td className="text-right" width="150">
                          <p style={{ marginBottom: 0 }}>Rs. {el.amount}</p>
                          <p
                            className="mb-0 text-primary text-right"
                            data-toggle="modal"
                            data-target="#invoiceInfoModal"
                            onClick={() => handleInvoice(el.id)}
                            style={{ cursor: "pointer" }}
                          >
                            More info
                          </p>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="3" className="text-muted">
                        No Recods
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <InvoiceInfoModal invId={invId} />
    </React.Fragment>
  );
}

export default withRouter(ManageInvoices);
