import React, { Component } from "react";
import axios from "axios";

import { isAuthenticated } from "../../../../auth/auth";

import UpdatePhoneModal from "../../modals/UpdatePhoneModal";

export default class ChangePhone extends Component {
  state = {
    postId: "",
    createdBy: "",
    phoneNumber: "",
    makePhonePublic: false,
    email: "",
    adId: "",
    emailVerified: false,

    phone: "",
    phoneModal: false,
    showCountryCodes: false,
    countries: [],
    search: "",
    code: sessionStorage.getItem("countryDialCode")
      ? sessionStorage.getItem("countryDialCode")
      : "+94",

    // phone verification
    phoneId: "",
    showPhoneVerification: false,
    timeCountDown: 30,
    showSendOptCode: false,
    sendAgainError: "",
    toManyAttempts: false,

    emailModal: false,
    updateEmail: "",
    loading: false,

    emailError: "",
    phoneError: "",

    // alerts
    successAlert: false,

    sentSuccess: false,
  };

  componentDidMount = () => {
    this.getLoginData();
  };

  getLoginData = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/v1/account`, {
        headers: {
          Authorization: `Bearer ${isAuthenticated()}`,
        },
      })
      .then((res) => {
        this.setState({
          phoneNumber: res.data.phoneNumber ? res.data.phoneNumber : "",
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    return (
      <>
        <div className="ad-form-sec">
          <form noValidate>
            <div className="form-row header">
              <div className="form-group col p-0 mb-0">
                <p className="heading mb-0">Change Phone Number</p>
                {/* <p className="info-desc">You can upload more than one photos</p> */}
              </div>
            </div>

            <div className="content" style={{ paddingTop: 10 }}>
              <div className="form-group col-sm-6 p-0 3">
                <label htmlFor="curPassword">Phone Number</label>

                <div class="input-group mb-2">
                  <input
                    type="text"
                    className="form-control"
                    value={this.state.phoneNumber}
                    disabled
                  />
                  <div
                    class="input-group-prepend phone-edit-append"
                    data-toggle="modal"
                    data-target="#updatePhoneModal"
                    style={{ cursor: "pointer" }}
                  >
                    <div class="input-group-text">
                      <i className="fas fa-pencil-alt"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>

        <UpdatePhoneModal lng={this.props.lng} />
      </>
    );
  }
}
