import React from "react";

import AdminLayout from "../../components/admin/layouts/AdminLayout";
import SettingsCom from "../../components/admin/settings/SettingsCom";

function Settings() {
  return (
    <AdminLayout>
      <SettingsCom />
    </AdminLayout>
  );
}

export default Settings;
