import React from "react";

import AdminLayout from "../../components/admin/layouts/AdminLayout";
import PaymentsCom from "../../components/admin/payments/PaymentsCom";

function Payments() {
  return (
    <AdminLayout>
      <PaymentsCom />
    </AdminLayout>
  );
}

export default Payments;
