// import packages
import React, { useState } from "react";
import axios from "axios";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";

import Breadcrumb from "../../admin/breadcrumb/Breadcrumb";
import PhotosUpload from "./ad-components/PhotosUpload";
import SubmitConfirmModal from "../modals/SubmitConfirmModal";

import { isAuthenticated } from "../../../auth/auth";

function CreateAdCom(props) {
  const [images, setImages] = useState([]);
  const [imagesErr, setImagesErr] = useState("");
  const [imagesData, setImagesData] = useState([]);
  const [imgLoading, setImgLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleUpload = (e) => {
    setImgLoading(true);
    let data = e.target.files[0];

    var bodyFormData = new FormData();
    bodyFormData.append("file", e.target.files[0]);

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/v1/generic/upload`,
        bodyFormData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        setImagesData([...imagesData, res.data.name]);
        setImages([...images, data]);
        setImagesErr("");

        // clear value
        document.getElementById("imgUpload").value = "";

        setImgLoading(false);
      })
      .catch((err) => console.log(err));
  };

  const delImages = (id) => {
    const filterImg = images.filter((el, i) => i !== id);
    const filterImgData = imagesData.filter((el, i) => i !== id);
    setImages(filterImg);
    setImagesData(filterImgData);
  };

  // validate
  const validate = () => {
    let imagesErr = "";

    if (images.length < 1) {
      imagesErr = "Atleast one image is required";
    }

    if (imagesErr) {
      setImagesErr(imagesErr);

      return false;
    }

    return true;
  };

  const handleFormSubmitBtn = (e) => {
    e.preventDefault();
    if (validate()) {
      let btn = document.getElementById("form-submit-btn");
      btn.setAttribute("data-toggle", "modal");
      btn.setAttribute("data-target", "#submitForm");

      document.getElementById("form-submit-btn").click();
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // if (validate()) {
    setLoading(true);

    const data = imagesData;

    axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/v1/agent_posts/scanned`,
      data: data,
      headers: {
        Authorization: `Bearer ${isAuthenticated()}`,
      },
    })
      .then((res) => {
        NotificationManager.success("Submitted successfully!", "Success");

        setImages([]);
        setImagesData([]);
        setLoading(false);

        document.getElementById("submitFormClose").click();
      })
      .catch((err) => {
        // this.setState({ errors: err.response.data, loading: false });
        // document.getElementById("name-label").focus();
        setLoading(false);
      });
    // }
  };

  return (
    <>
      <div className="content-col">
        <Breadcrumb title="Submit an Ad" />

        <div className="container-fluid inner-content">
          <div className="ad-form-sec">
            <form noValidate>
              <div className="form-row header">
                <div className="form-group col px-sm-2 mb-0">
                  <p className="heading mb-0">Upload filled form photos</p>
                  <p className="info-desc">
                    You can upload more than one photos
                  </p>
                </div>
              </div>

              <div className="content" style={{ paddingTop: 10 }}>
                <PhotosUpload
                  images={images}
                  handleUpload={handleUpload}
                  delImages={delImages}
                  imgLoading={imgLoading}
                />
                <p
                  style={{
                    fontSize: 12,
                    color: "#dc3545",
                    marginLeft: "1.5%",
                  }}
                >
                  {imagesErr}
                </p>
              </div>

              <button
                type="button"
                className="btn-submit mt-3"
                id="form-submit-btn"
                style={{ width: "auto", float: "right" }}
                onClick={handleFormSubmitBtn}
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
      <SubmitConfirmModal handleSubmit={handleSubmit} loading={loading} />

      <NotificationContainer />
    </>
  );
}

export default CreateAdCom;
