import React from "react";

import AdminLayout from "../../components/admin/layouts/AdminLayout";
import OnlinePaymentsCom from "../../components/admin/checkout/OnlinePaymentsCom";

function OnlinePayments() {
  return (
    <AdminLayout>
      <OnlinePaymentsCom />
    </AdminLayout>
  );
}

export default OnlinePayments;
