import React from "react";

import AdminLayout from "../../components/admin/layouts/AdminLayout";
import PaymentMethodsCom from "../../components/admin/checkout/PaymentMethodsCom";

function PaymentMethods() {
  return (
    <AdminLayout>
      <PaymentMethodsCom />
    </AdminLayout>
  );
}

export default PaymentMethods;
