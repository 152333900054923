import React, { useState } from "react";
import { Link, withRouter } from "react-router-dom";
import classnames from "classnames";
import axios from "axios";
import qs from "qs";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import { storeUserDetails } from "../../../actions/storeUserDetails";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import LogoCol from "./LogoCol";

function CommonLogin(props) {
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [phoneErr, setPhoneErr] = useState("");
  const [passwordErr, setPasswordErr] = useState("");
  const [authErr, setAuthErr] = useState("");
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setPassword(e.target.value);
    setPasswordErr("");
  };

  const handlePhone = (phone) => {
    const str = phone.split("94")[1];
    const newPhone = parseInt(str, 10);

    setPhone(`94${newPhone}`);
    setPhoneErr("");
  };

  // validate
  const validate = () => {
    let phoneErr = "";
    let passwordErr = "";

    if (!phone) {
      phoneErr = "Phone is required";
    }

    if (!password) {
      passwordErr = "Password is required";
    }

    if (phoneErr || passwordErr) {
      setPhoneErr(phoneErr);
      setPasswordErr(passwordErr);

      return false;
    }

    return true;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      setLoading(true);

      const data = qs.stringify({
        userId: `+${phone}`,
        password: password,
      });

      axios
        .post(`${process.env.REACT_APP_API_URL}/v1/auth/create_session`, data, {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded;",
          },
        })
        .then((res) => {
          localStorage.setItem("at", res.data.token);

          axios
            .get(`${process.env.REACT_APP_API_URL}/v1/account`, {
              headers: {
                Authorization: `Bearer ${res.data.token}`,
              },
            })
            .then((res) => {
              if (res.data.role === "AGENT") {
                localStorage.setItem("un", res.data.name);
                localStorage.setItem("ur", res.data.role);
                localStorage.setItem("ac", res.data.agentInfo.code);

                setPhone("");
                setPassword("");
                setPhoneErr("");
                setPasswordErr("");
                setLoading(false);

                props.history.push("/dashboard/submit-ad");
              } else if (res.data.role === "USER") {
                setAuthErr("Access denied for this user");
                setLoading(false);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          if (
            err.response.data.code === 1102 ||
            err.response.data.code === 1104
          ) {
            setAuthErr("Incorrect username or/and password!");
          }
          setLoading(false);
        });
    }
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12 col-md-6 login-reg-col">
          <div className="login-reg-container">
            <p className="heading">Welcome to Agent Dashboard</p>
            <p style={{ fontSize: 12, color: "#dc3545", marginBottom: 5 }}>
              {authErr}
            </p>
            <form noValidate>
              <div className="input-group col p-0 mb-3">
                <label htmlFor="phone1">Phone Number</label>
                <div className="input-group">
                  <PhoneInput
                    country="lk"
                    disableDropdown={true}
                    value={props.phone1}
                    enableSearch={true}
                    disableSearchIcon={true}
                    countryCodeEditable={false}
                    prefix="+"
                    onChange={(phone) => handlePhone(phone)}
                  />
                </div>
                <p className="cus-error">{phoneErr}</p>
              </div>
              <div className="form-group p-0 mb-0">
                <label htmlFor="password">Password</label>
                <input
                  type="password"
                  className={classnames("form-control", {
                    "is-invalid": passwordErr,
                  })}
                  id="password"
                  name="password"
                  onChange={handleChange}
                  value={password}
                />
                <div className="invalid-feedback">{passwordErr}</div>
              </div>

              <button
                type="submit"
                className="btn-submit"
                onClick={handleSubmit}
              >
                {loading ? (
                  <div
                    className="spinner-border spinner-border-sm text-light"
                    role="status"
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : null}
                {loading ? "" : "Login"}
              </button>
            </form>
            <Link to="/forget-password" className="forget-password-link">
              Forgot your Password?
            </Link>
            {/* <p className="mt-2 form-bellow-link">
              Don't have an account?{" "}
              <Link to="/Create-an-account" className="login-now-link">
                Create an account
              </Link>
            </p> */}
          </div>
        </div>

        <LogoCol />
      </div>
    </div>
  );
}

const matchDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      storeUserDetails,
    },
    dispatch
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(
  mapStateToProps,
  matchDispatchToProps
)(withRouter(CommonLogin));
