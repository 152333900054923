// import packages
import React from "react";

import Breadcrumb from "../breadcrumb/Breadcrumb";
import ChangePhone from "./components/ChangePhone";
import ChangePassword from "./components/ChangePassword";

function SettingsCom(props) {
  return (
    <div className="content-col">
      <Breadcrumb title="Settings" />

      <div className="container-fluid inner-content">
        <ChangePhone lng={true} />
        <ChangePassword />
      </div>
    </div>
  );
}

export default SettingsCom;
