import React, { useState, useEffect } from "react";
import axios from "axios";
import { PhotoProvider, PhotoConsumer } from "react-photo-view";
import "react-photo-view/dist/index.css";

import { isAuthenticated } from "../../../auth/auth";

function AdImages(props) {
  const [imageData, setImageData] = useState([]);

  useEffect(() => {
    setImageData([]);

    if (props.images && props.images.length > 0) {
      for (let i = 0; i < props.images.length; i++) {
        axios
          .get(
            `${process.env.REACT_APP_API_URL}/v1/agent_posts/scanned/${props.scanId}/images/${props.images[i]}?width=500&height=500`,
            {
              headers: {
                Authorization: `Bearer ${isAuthenticated()}`,
              },
              responseType: "blob",
            }
          )
          .then((res) => {
            let data = URL.createObjectURL(res.data);

            setImageData((imageData) => [...imageData, data]);
          })
          .catch((err) => {
            console.log(err);
            return [];
          });
      }
    } else {
      setImageData([]);
    }
  }, [props.scanId]);

  return (
    <PhotoProvider>
      {imageData.map((item, index) => (
        <PhotoConsumer key={index} src={item}>
          <img src={item} alt="scaned forms" className="photos" width="100" />
          {/* <span className="form-photo-links">Form {index + 1}, </span> */}
        </PhotoConsumer>
      ))}
    </PhotoProvider>
  );
}

export default AdImages;
