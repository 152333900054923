import React from "react";
import AdImages from "../all-ads/AdImages";

import Close from "../../../images/close-white.png";

function FormPhotosModal(props) {
  return (
    <div
      className="modal fade"
      id="formPhotosModal"
      tabIndex="-1"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <p className="modal-title">{props.images.length} Captures</p>
            <img
              src={Close}
              alt="close"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              width="50px"
              style={{ cursor: "pointer" }}
            />
          </div>
          <div className="modal-body">
            <AdImages images={props.images} scanId={props.scanId} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default FormPhotosModal;
