import React from "react";

import AdminLayout from "../../components/admin/layouts/AdminLayout";
import BankTransferCom from "../../components/admin/checkout/BankTransferCom";

function BankTransfer() {
  return (
    <AdminLayout>
      <BankTransferCom />
    </AdminLayout>
  );
}

export default BankTransfer;
