import React from "react";

import AdminLayout from "../../components/admin/layouts/AdminLayout";
import ManageInvoices from "../../components/admin/invoices/ManageInvoices";

function Invoices() {
  return (
    <AdminLayout>
      <ManageInvoices />
    </AdminLayout>
  );
}

export default Invoices;
