import React, { useState } from "react";
import { Link, withRouter } from "react-router-dom";
import classnames from "classnames";
import axios from "axios";
import qs from "qs";
import PhoneInput from "react-phone-input-2";

import { storeUserDetails } from "../../../actions/storeUserDetails";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import LogoCol from "../login/LogoCol";

import { isAuthenticated } from "../../../auth/auth";

function ForgetPasswordCom(props) {
  const [phone, setPhone] = useState("");
  const [phoneId, setPhoneId] = useState("");
  const [code, setCode] = useState("");
  const [password, setPassword] = useState("");
  const [conPassword, setConPassword] = useState("");
  const [phoneErr, setPhoneErr] = useState("");
  const [codeErr, setCodeErr] = useState("");
  const [passwordErr, setPasswordErr] = useState("");
  const [conPasswordErr, setConPasswordErr] = useState("");
  const [continueloading, setContinueloading] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    if (e.target.name === "code") {
      setCode(e.target.value);
      setCodeErr("");
    } else if (e.target.name === "password") {
      setPassword(e.target.value);
      setPasswordErr("");
    } else {
      setConPassword(e.target.value);
      setConPasswordErr("");
    }
  };

  const handlePhone = (phone) => {
    const str = phone.split("94")[1];
    const newPhone = parseInt(str, 10);

    setPhone(`94${newPhone}`);
    setPhoneErr("");
  };

  // validate phone
  const validateReset = () => {
    let phoneErr = "";

    if (!phone) {
      phoneErr = "Phone is required";
    }

    if (phoneErr) {
      setPhoneErr(phoneErr);

      return false;
    }

    return true;
  };

  const sendResetCode = (e) => {
    e.preventDefault();
    if (validateReset()) {
      setContinueloading(true);

      const data = qs.stringify({
        userId: `+${phone}`,
      });

      axios
        .post(
          `${process.env.REACT_APP_API_URL}/v1/auth/request_reset_password`,
          data,
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        )
        .then((res) => {
          setPhone("");
          setPhoneId(res.data.id);
        })
        .catch((err) => {
          setContinueloading(false);

          if (err.response.data.code === 1001) {
            setPhoneErr("Phone does not exist");
          }
        });
    }
  };

  // validate
  const validate = () => {
    let codeErr = "";
    let passwordErr = "";
    let conPasswordErr = "";

    if (!code) {
      codeErr = true ? "Code is required" : "කේතය අවශ්‍ය වේ";
    }

    if (!password) {
      passwordErr = true ? "Password is required" : "මුරපදය අවශ්‍ය වේ";
    } else if (password.length < 8) {
      passwordErr = true
        ? "Password should be at least 8 characters long"
        : "මුරපදය අවම වශයෙන් අක්ෂර 8 ක් විය යුතුය";
    }

    if (!conPassword) {
      conPasswordErr = true
        ? "Confirm password is required"
        : "තහවුරු මුරපදය අවශ්‍ය වේ";
    } else if (conPassword !== password) {
      conPasswordErr = true ? "Passwords should match" : "මුරපද ගැලපිය යුතුය";
    }

    if (codeErr || passwordErr || conPasswordErr) {
      setCodeErr(codeErr);
      setPasswordErr(passwordErr);
      setConPasswordErr(conPasswordErr);
      return false;
    }

    return true;
  };

  const handlePasswordReset = () => {
    if (validate()) {
      setLoading(true);

      const data = `password=${password}&phoneId=${phoneId}&code=${code}`;

      axios
        .post(
          `${process.env.REACT_APP_API_URL}/v1/auth/reset_password_phone`,
          data,
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        )
        .then((res) => {
          setLoading(false);

          localStorage.setItem("at", res.data.token);

          axios
            .get(`${process.env.REACT_APP_API_URL}/v1/account`, {
              headers: {
                Authorization: `Bearer ${isAuthenticated()}`,
              },
            })
            .then((res) => {
              localStorage.setItem("ur", res.data.role);
              localStorage.setItem("un", res.data.name);
              localStorage.setItem("ac", res.data.agentInfo.code);

              if (res.data.role === "AGENT") {
                props.history.push("/dashboard/submit-ad");
              }
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          if (err.response.data.code === 1202) {
            setCodeErr("Invalid code");
          }

          setLoading(false);
        });
    }
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <LogoCol />

        <div className="col login-reg-col">
          <div className="login-reg-container">
            <p className="heading">Reset Password</p>
            <form noValidate>
              {!phoneId ? (
                <React.Fragment>
                  <div className="form-group p-0">
                    <label htmlFor="phone1">Phone Number</label>
                    <div className="input-group">
                      <PhoneInput
                        country="lk"
                        disableDropdown={true}
                        value={props.phone1}
                        enableSearch={true}
                        disableSearchIcon={true}
                        countryCodeEditable={false}
                        prefix="+"
                        onChange={(phone) => handlePhone(phone)}
                      />
                    </div>
                    <p className="cus-error">{phoneErr}</p>
                  </div>

                  <button
                    type="button"
                    className="btn-submit"
                    onClick={sendResetCode}
                  >
                    {loading ? (
                      <div
                        className="spinner-border spinner-border-sm text-light"
                        role="status"
                      >
                        <span className="sr-only">Loading...</span>
                      </div>
                    ) : null}
                    {loading ? "" : "Continue"}
                  </button>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <div className="form-group p-0 mb-3">
                    <label htmlFor="code">Password Reset Code</label>
                    <input
                      type="text"
                      className={classnames("form-control", {
                        "is-invalid": codeErr,
                      })}
                      id="code"
                      name="code"
                      onChange={handleChange}
                      value={code}
                    />
                    <div className="invalid-feedback">{codeErr}</div>
                  </div>

                  <div className="form-group p-0 mb-3">
                    <label htmlFor="password">Create Password</label>
                    <input
                      type="password"
                      className={classnames("form-control", {
                        "is-invalid": passwordErr,
                      })}
                      id="password"
                      name="password"
                      onChange={handleChange}
                      value={password}
                    />
                    <div className="invalid-feedback">{passwordErr}</div>
                  </div>

                  <div className="form-group p-0 mb-0">
                    <label htmlFor="conPassword">Confirm Password</label>
                    <input
                      type="password"
                      className={classnames("form-control", {
                        "is-invalid": conPasswordErr,
                      })}
                      id="conPassword"
                      name="conPassword"
                      onChange={handleChange}
                      value={conPassword}
                    />
                    <div className="invalid-feedback">{conPasswordErr}</div>
                  </div>

                  <button
                    type="button"
                    className="btn-submit"
                    onClick={handlePasswordReset}
                  >
                    {loading ? (
                      <div
                        className="spinner-border spinner-border-sm text-light"
                        role="status"
                      >
                        <span className="sr-only">Loading...</span>
                      </div>
                    ) : null}
                    {loading ? "" : "Confirm"}
                  </button>
                </React.Fragment>
              )}
            </form>
            {!phoneId ? (
              <span className="back" onClick={() => props.history.goBack()}>
                <i className="fas fa-angle-left mr-1"></i>
                Back
              </span>
            ) : (
              <span className="back" onClick={() => setPhoneId("")}>
                <i className="fas fa-angle-left mr-1"></i>
                Back
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

const matchDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      storeUserDetails,
    },
    dispatch
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(
  mapStateToProps,
  matchDispatchToProps
)(withRouter(ForgetPasswordCom));
