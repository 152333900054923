import React from "react";
import { Link, withRouter } from "react-router-dom";

import { connect } from "react-redux";

import Close from "../../../images/close-white.png";

function SideBar(props) {
  return (
    <div className="fixed-div">
      <img
        src={Close}
        className="close-btn"
        alt="close"
        onClick={props.closeDrawer}
      />
      <i className="fas fa-user-circle profile-pic"></i>
      <p className="name">Hi {localStorage.getItem("un")}</p>
      <p className="role">(Agent)</p>

      <button
        className="btn btn-sm create-ad-btn"
        onClick={() => props.history.push("/dashboard/submit-ad")}
      >
        Submit an Ad
      </button>
      {/* <div className="panel">
        <Link
          to="/dashboard/create-ad"
          className={
            props.history.location.pathname === "/dashboard/create-ad"
              ? "active-tab"
              : ""
          }
        >
          <i className="far fa-plus-square mr-2"></i> Create Ad
        </Link>
      </div> */}
      <div className="panel">
        <Link
          to="/dashboard/all-ads"
          className={
            props.history.location.pathname === "/dashboard/all-ads"
              ? "active-tab"
              : ""
          }
        >
          <i className="far fa-newspaper mr-2"></i> All Ads
        </Link>
      </div>
      <div className="panel">
        <Link
          to="/dashboard/invoices"
          className={
            props.history.location.pathname === "/dashboard/invoices"
              ? "active-tab"
              : ""
          }
        >
          <i className="fas fa-receipt mr-2"></i>
          Invoices
        </Link>
      </div>
      <div className="panel">
        <Link
          to="/dashboard/payments"
          className={
            props.history.location.pathname === "/dashboard/payments"
              ? "active-tab"
              : ""
          }
        >
          <i className="fas fa-dollar-sign mr-2"></i>
          Payments
        </Link>
      </div>
      <div className="panel" style={{ borderBottom: "1px solid #5d5d5d" }}>
        <Link
          to="/dashboard/settings"
          className={
            props.history.location.pathname === "/dashboard/settings"
              ? "active-tab"
              : ""
          }
        >
          <i className="fas fa-cog mr-2"></i> Settings
        </Link>
      </div>
      {/* <div className="panel" style={{ borderBottom: "1px solid #5d5d5d" }}>
        <Link
          to="/dashboard/change-password"
          className={
            props.history.location.pathname === "/dashboard/change-password"
              ? "active-tab"
              : ""
          }
        >
          <i className="fas fa-key mr-2"></i> Change Password
        </Link>
      </div> */}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    isEmptyPost:
      Object.keys(state.user.userData).length === 0 &&
      state.user.userData.constructor === Object,
    user: state.user.userData,
  };
};

export default connect(mapStateToProps, null)(withRouter(SideBar));
