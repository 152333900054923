import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

// import DetailPage from "./pages/DetailPage";
// import Shop from "./pages/Shop";

// visitors
import Login from "./pages/Login";
import Register from "./pages/Register";
import ForgetPassword from "./pages/ForgetPassword";

// customer
// import CreateAd from "./pages/customer/CreateAd";

// admin
import CreateAd from "./pages/admin/CreateAd";
import AllAds from "./pages/admin/AllAds";
import Invoices from "./pages/admin/Invoices";
import PaymentMethods from "./pages/admin/PaymentMethods";
import OnlinePayments from "./pages/admin/OnlinePayments";
import BankTransfer from "./pages/admin/BankTransfer";
import Payments from "./pages/admin/Payments";
import Settings from "./pages/admin/Settings";

// load isAuthenticated method
import { isAuthenticated } from "./auth/auth";

// LoginRoute
const LoginRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      !isAuthenticated() ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/dashboard/submit-ad",
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

// create a private route for admin
const AdminRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isAuthenticated() ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: "/", state: { from: props.location } }} />
      )
    }
  />
);

function App() {
  return (
    <Router>
      <Switch>
        <LoginRoute path="/" exact component={Login} />
        <Route path="/create-an-account" exact component={Register} />
        <Route path="/forget-password" exact component={ForgetPassword} />

        {/* <CustomerRoute
          path="/my-account/create-an-ad"
          exact
          component={CreateAd}
        /> */}

        <AdminRoute path="/dashboard/submit-ad" exact component={CreateAd} />
        <AdminRoute path="/dashboard/all-ads" exact component={AllAds} />
        <AdminRoute path="/dashboard/invoices" exact component={Invoices} />
        <AdminRoute
          path="/dashboard/select-payment-method"
          exact
          component={PaymentMethods}
        />
        <AdminRoute
          path="/dashboard/checkout/online-payments"
          exact
          component={OnlinePayments}
        />
        <AdminRoute
          path="/dashboard/checkout/bank-transfer"
          exact
          component={BankTransfer}
        />
        <AdminRoute path="/dashboard/payments" exact component={Payments} />
        <AdminRoute path="/dashboard/settings" exact component={Settings} />
        <AdminRoute
          path="/dashboard/change-password"
          exact
          component={AllAds}
        />
      </Switch>
    </Router>
  );
}

export default App;
