import React, { Component } from "react";
import axios from "axios";
import classnames from "classnames";
import qs from "qs";
import ReactCodeInput from "react-verification-code-input";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";

import Close from "../../../images/close-white.png";
import { isAuthenticated } from "../../../auth/auth";

var timer;

export class UpdatePhoneModal extends Component {
  state = {
    postId: "",

    phoneNumber: "",

    phone: "",
    phoneModal: false,
    code: "+94",

    // phone verification
    phoneId: "",
    showPhoneVerification: false,
    timeCountDown: 30,
    showSendOptCode: false,
    sendAgainError: "",
    toManyAttempts: false,

    loading: false,
    phoneError: "",

    // alerts
    sentSuccess: false,
  };

  componentDidMount = () => {
    this.getPost();
  };

  getLoginData = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/v1/account`, {
        headers: {
          Authorization: `Bearer ${isAuthenticated()}`,
        },
      })
      .then((res) => {
        this.setState({
          phoneNumber: res.data.phoneNumber ? res.data.phoneNumber : "",
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getPost = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/v1/account/posts`, {
        headers: {
          Authorization: `Bearer ${isAuthenticated()}`,
        },
      })
      .then((res) => {
        this.setState({
          postId: res.data[0].id,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // handle inputs
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      [e.target.name + "Error"]: "",
    });
  };

  handlePhone = (e) => {
    const str = e.target.value.toString();

    this.setState({ phone: parseInt(str, 10), phoneError: "" });
  };

  // validate
  validatePhone = () => {
    let phoneError = "";

    if (this.state.phone === "") {
      phoneError = this.props.lng
        ? "Phone number is required"
        : "දුරකථන අංකය අවශ්‍ය වේ";
    }

    if (phoneError) {
      this.setState({
        phoneError,
      });
      return false;
    }

    return true;
  };

  optTimer = () => {
    if (this.state.timeCountDown === 0) {
      clearInterval(timer);

      this.setState({ showSendOptCode: true });
    } else {
      this.setState({ timeCountDown: this.state.timeCountDown - 1 });
    }
  };

  handleRegister = () => {
    if (this.validatePhone()) {
      this.setState({
        loading: true,
        toManyAttempts: false,
        verificationError: false,
        sendAgainError: "",
      });

      if (this.state.timeCountDown === 0 || this.state.timeCountDown === 30) {
        axios
          .post(
            `${process.env.REACT_APP_API_URL}/v1/phonenumbers`,
            qs.stringify({
              number: this.state.code + this.state.phone,
              channel: "sms",
            }),
            {
              headers: {
                "Content-Type": "application/x-www-form-urlencoded",
              },
            }
          )
          .then((res) => {
            this.setState({
              phoneId: res.data.id,
              timeCountDown: 30,
              showSendOptCode: false,
              showPhoneVerification: true,
              loading: false,
            });

            timer = setInterval(this.optTimer, 1000);
          })
          .catch((err) => {
            console.log(err);

            if (err.response.data.code === 1201) {
              this.setState({
                toManyAttempts: true,
              });
            } else if (err.response.data.code === 1000) {
              this.setState({
                phoneError: this.props.lng
                  ? "Could not send sms to this number"
                  : "මෙම අංකයට කෙටි පණිවුඩ යැවීමට නොහැකිය",
              });
            } else if (err.response.data.code === 1203) {
              this.setState({
                phoneError: this.props.lng
                  ? "Invalid phone number format"
                  : "දුරකථන අංකය වැරදිය",
              });
            }
            this.setState({
              showPhoneVerification: false,
              loading: false,
            });
          });
      } else {
        this.setState({
          toManyAttempts: true,
          loading: false,
        });
      }
    }
  };

  sendToVerify = () => {
    this.setState({
      showSendOptCode: false,
      timeCountDown: 30,
    });
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/v1/phonenumbers`,
        qs.stringify({
          number: this.state.code + this.state.phone,
          channel: "sms",
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((res) => {
        this.setState({
          phoneId: res.data.id,
        });

        timer = setInterval(this.optTimer, 1000);
      })
      .catch((err) => {
        console.log(err);
        if (err.response.data.code === 1000) {
          this.setState({
            sendAgainError: this.props.lng
              ? "Could not send sms to this number"
              : "මෙම අංකයට කෙටි පණිවුඩ යැවීමට නොහැකිය",
          });
        }
      });
  };

  sendToVerifyByCall = () => {
    this.setState({
      showSendOptCode: false,
      timeCountDown: 30,
    });

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/v1/phonenumbers`,
        qs.stringify({
          number: this.state.code + this.state.phone,
          channel: "call",
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((res) => {
        this.setState({
          phoneId: res.data.id,
        });

        timer = setInterval(this.optTimer, 1000);
      })
      .catch((err) => {
        if (err.response.data.code === 1000) {
          this.setState({
            sendAgainError: this.props.lng
              ? "Could not make voice call to this number"
              : "මෙම අංකයට ඇමතුමක් ලබා ගැනීමට නොහැකිය",
          });
        }
      });
  };

  handleOPT = (code) => {
    this.setState({ optCode: code, verificationError: false });
  };

  verify = () => {
    this.setState({ loading: true });
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/v1/phonenumbers/${this.state.phoneId}/verify`,
        qs.stringify({ code: this.state.optCode }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((res) => {
        const data = `phoneId=${res.data.id}`;

        // update_phonenumber
        axios
          .post(
            `${process.env.REACT_APP_API_URL}/v1/account/update_phonenumber`,
            data,
            {
              headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: `Bearer ${isAuthenticated()}`,
              },
            }
          )
          .then((res) => {
            this.setState({
              loading: false,
              phoneId: "",
              phone: "",
              showPhoneVerification: false,
              timeCountDown: 30,
              showSendOptCode: false,
              sendAgainError: "",
              toManyAttempts: false,
            });

            NotificationManager.success("Updated successfully!", "Success");

            document.getElementById("updatePhoneModalClose").click();

            setTimeout(() => window.location.reload(), 1000);
            window.location.reload();
          })
          .catch((err) => {
            if (err.response.data.code === 1107) {
              this.setState({
                phoneError: this.props.lng
                  ? "Phone number is used by another account"
                  : "දුරකථන අංකය වෙනත් ගිණුමක් භාවිතා කරයි",
                showPhoneVerification: false,
              });
            } else {
              this.setState({
                errorAlert: true,
                errorCode: err.response.data.code,
              });
            }

            this.setState({
              loading: false,
            });
          });
      })
      .catch((err) => {
        this.setState({
          verificationError: true,
          loading: false,
        });
      });
  };

  // login when press the enter key
  handleRegisterKeyUp = (e) => {
    if (e.keyCode === 13) {
      // Trigger the button element with a click
      document.getElementById("user-register-btn").click();
    }
  };

  handleClose = () => {
    this.setState({
      phoneModal: false,
      phone: "",
      phoneError: "",
      phoneId: "",
      showPhoneVerification: false,
      timeCountDown: 30,
      showSendOptCode: false,
      sendAgainError: "",
      toManyAttempts: false,
    });
  };

  render() {
    return (
      <>
        <div
          className="modal fade updatePhoneModal"
          id="updatePhoneModal"
          tabIndex="-1"
          aria-labelledby="updatePhoneModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <p className="modal-title" id="updatePhoneModalLabel">
                  Update Phone Number
                </p>

                <img
                  src={Close}
                  alt="close"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  width="50px"
                  style={{ cursor: "pointer" }}
                  id="updatePhoneModalClose"
                />
              </div>
              <div className="modal-body">
                {!this.state.showPhoneVerification ? (
                  <div className="form-group col-10 col-sm-6 mx-auto p-0 mt-3">
                    <label className="text-center w-100" htmlFor="curPassword">
                      Phone Number
                    </label>
                    <div class="input-group mb-2">
                      <div
                        class="input-group-prepend"
                        style={{ cursor: "pointer" }}
                      >
                        <div class="input-group-text">+94</div>
                      </div>
                      <input
                        type="number"
                        className={classnames("form-control", {
                          "is-invalid": this.state.phoneError,
                        })}
                        value={this.state.phone}
                        onChange={this.handlePhone}
                      />
                      <div className="invalid-feedback">
                        {this.state.phoneError}
                      </div>
                    </div>
                  </div>
                ) : (
                  <React.Fragment>
                    <p
                      className="modal-heading"
                      style={{
                        textAlign: "center",
                        border: 0,
                        marginBottom: 25,
                      }}
                    >
                      {this.props.lng ? (
                        "Verify Phone Number"
                      ) : (
                        <span className="sinhala-w-600 sinhala-size-14">
                          දුරකථන අංකය තහවුරු කරන්න
                        </span>
                      )}
                    </p>

                    <p className="your-phone">
                      {this.props.lng
                        ? "Your Phone Number"
                        : "ඔබගේ දුරකථන අංකය"}
                    </p>
                    <p className="phone">
                      {this.state.code} {this.state.phone}
                    </p>
                    <p
                      className="change"
                      onClick={() =>
                        this.setState({ showPhoneVerification: false })
                      }
                    >
                      {this.props.lng ? "(Change)" : "වෙනස් කරන්න"}
                    </p>
                    <p className="enter-code">
                      {this.props.lng ? "Enter Code" : "කේතය ඇතුලත් කරන්න"}{" "}
                    </p>
                    <ReactCodeInput
                      fields={4}
                      onChange={(val) => this.handleOPT(val)}
                    />
                    <p className="opt-error" style={{ textAlign: "center" }}>
                      {this.state.verificationError
                        ? this.props.lng
                          ? "Verification code is invalid"
                          : "කේතය වලංගු නොවේ"
                        : null}
                    </p>
                    <p className="opt">
                      {this.props.lng
                        ? "Didn't receive OTP?"
                        : "OTP ලැබුණේ නැද්ද?"}
                    </p>
                    <p className="opt" style={{ marginTop: 2 }}>
                      {this.state.showSendOptCode ? (
                        <span
                          onClick={this.sendToVerify}
                          style={{ cursor: "pointer" }}
                        >
                          {this.props.lng ? (
                            "Send again"
                          ) : (
                            <span className="sinhala-w-600">නැවත යවන්න</span>
                          )}
                        </span>
                      ) : (
                        <span style={{ textDecoration: "none" }}>
                          {this.props.lng ? "Retry in" : "තත්පර"}{" "}
                          {this.state.timeCountDown}
                          {this.props.lng ? "s" : "කින් පසුව උත්සහ කරන්න"}
                        </span>
                      )}
                      {this.state.showSendOptCode ? (
                        <React.Fragment>
                          &nbsp; | &nbsp;
                          <span
                            onClick={this.sendToVerifyByCall}
                            style={{ cursor: "pointer" }}
                          >
                            {this.props.lng ? (
                              "Get Code via Phone Call"
                            ) : (
                              <span className="sinhala-w-600">
                                දුරකථන ඇමතුමක් හරහා කේතය ලබා ගන්න
                              </span>
                            )}
                          </span>
                        </React.Fragment>
                      ) : null}
                    </p>
                    {this.state.sendAgainError && (
                      <p
                        style={{
                          fontSize: 12,
                          color: "#f44336",
                          marginRight: 12,
                          marginTop: 5,
                        }}
                      >
                        * {this.state.sendAgainError}
                      </p>
                    )}

                    <button
                      className="btn-submit my-3"
                      onClick={this.verify}
                      style={{ margin: "auto", width: 250 }}
                    >
                      {this.state.loading ? (
                        <div
                          class="spinner-border spinner-border-sm"
                          role="status"
                        >
                          <span class="sr-only">Loading...</span>
                        </div>
                      ) : this.props.lng === true ? (
                        "Update"
                      ) : (
                        <span className="sinhala-w-600 sinhala-size-10">
                          යාවත්කාලීන කරන්න
                        </span>
                      )}
                    </button>
                    <p
                      className="back"
                      onClick={() =>
                        this.setState({ showPhoneVerification: false })
                      }
                    >
                      {this.props.lng ? "< BACK" : "< ආපසු යන්න"}
                    </p>
                  </React.Fragment>
                )}
              </div>
              {!this.state.showPhoneVerification && (
                <div className="modal-footer border-0 p-2">
                  {this.state.toManyAttempts && (
                    <p
                      style={{
                        fontSize: 12,
                        color: "#f44336",
                        marginRight: 12,
                        marginBottom: 7,
                        textAlign: "center",
                      }}
                    >
                      {this.props.lng
                        ? this.state.timeCountDown !== 0
                          ? `* Too many attempts. Please try again in 
                            ${this.state.timeCountDown} seconds.`
                          : ""
                        : this.state.timeCountDown !== 0
                        ? `* කරුණාකර තත්පර ${this.state.timeCountDown} කින් නැවත උත්සාහ කරන්න.`
                        : ``}
                    </p>
                  )}
                  <button
                    className="btn-submit mt-0"
                    onClick={this.handleRegister}
                    id="user-register-btn"
                    style={{ float: "right", width: 110 }}
                  >
                    {this.state.loading ? (
                      <div
                        class="spinner-border spinner-border-sm"
                        role="status"
                      >
                        <span class="sr-only">Loading...</span>
                      </div>
                    ) : (
                      <span>
                        Continue <i class="fas fa-angle-right"></i>
                      </span>
                    )}
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>

        <NotificationContainer />
      </>
    );
  }
}

export default UpdatePhoneModal;
