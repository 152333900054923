import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import classnames from "classnames";

import Breadcrumb from "../breadcrumb/Breadcrumb";

import { isAuthenticated } from "../../../auth/auth";

class PaymentMethodsCom extends Component {
  state = {
    invId: "",
    actualDue: "",
    dueAmount: "",
    dueAmountErr: "",

    loading: false,
  };

  componentDidMount = () => {
    let url_string = document.location.href;
    let url = new URL(url_string);
    let invoiceId = url.searchParams.get("invId");

    this.setState({ invId: invoiceId });

    // invoice
    axios
      .get(`${process.env.REACT_APP_API_URL}/v1/agent_invoices/${invoiceId}`, {
        headers: {
          Authorization: `Bearer ${isAuthenticated()}`,
        },
      })
      .then((res) => {
        axios
          .get(`${process.env.REACT_APP_API_URL}/v1/agent_payments`, {
            headers: {
              Authorization: `Bearer ${isAuthenticated()}`,
            },
          })
          .then((result) => {
            const filterByInvId = result.data.filter(
              (el) => el.invoiceId === res.data.id
            );
            if (filterByInvId.length > 0) {
              const totPaid = filterByInvId.reduce(
                (currentValue, nextValue) => {
                  return currentValue + nextValue.amount;
                },
                0
              );

              this.setState({
                actualDue:
                  res.data.amount + res.data.carriedForwardAmount - totPaid,
                dueAmount: sessionStorage.getItem("payment_amt")
                  ? sessionStorage.getItem("payment_amt")
                  : res.data.amount + res.data.carriedForwardAmount - totPaid,
              });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
        // setLoading(false);
      });

    // paymentmethods
    axios
      .get(`${process.env.REACT_APP_API_URL}/v1/generic/payment_methods`)
      .then((res) => {
        this.setState({ allPayMethods: res.data });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleChange = (e) => {
    this.setState({
      dueAmount: e.target.value,
      dueAmountErr: "",
    });
  };

  handleOnlinePayments = () => {
    if (
      this.state.dueAmount >= 200 &&
      this.state.dueAmount <= this.state.actualDue
    ) {
      sessionStorage.setItem("payment_amt", this.state.dueAmount);
      this.props.history.push(
        `/dashboard/checkout/online-payments?invId=${this.state.invId}`
      );
    } else {
      this.setState({
        dueAmountErr: "Invalid payment amount",
        loading: false,
      });
    }
  };

  handleBankDeposits = () => {
    if (
      this.state.dueAmount >= 200 &&
      this.state.dueAmount <= this.state.actualDue
    ) {
      sessionStorage.setItem("payment_amt", this.state.dueAmount);
      this.props.history.push(`/dashboard/checkout/bank-transfer`);
    } else {
      this.setState({
        dueAmountErr: "Invalid payment amount",
        loading: false,
      });
    }
  };

  render() {
    return (
      <>
        <div className="content-col">
          <Breadcrumb title="Checkout" />

          <div className="container-fluid inner-content">
            <div className="ad-form-sec">
              <form noValidate>
                <div className="content" style={{ paddingTop: 10 }}>
                  <div className="invoice-div">
                    <p className="heading">Invoice</p>
                    <table
                      className="table border mb-0"
                      style={{ fontSize: "14px" }}
                    >
                      <tbody>
                        <tr>
                          <td>Total Due</td>
                          <td className="text-right">
                            Rs. {this.state.actualDue}
                          </td>
                        </tr>
                        <tr className="text-danger">
                          <td>* Minimum Payment Amount</td>
                          <td className="text-right">Rs. 200</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <p className="payment-amount">Amount (Rs.)</p>
                  <p className="amount-change-desc">
                    You can change the payment amount in bellow field
                  </p>
                  <div className="form-group p-0 mb-0 col-sm-3 m-auto">
                    <input
                      type="number"
                      className={classnames("form-control", {
                        "is-invalid": this.state.dueAmountErr,
                      })}
                      name="dueAmount"
                      onChange={this.handleChange}
                      value={this.state.dueAmount}
                      min="0"
                      style={{ textAlign: "center" }}
                    />
                    <div className="invalid-feedback">
                      {this.state.dueAmountErr}
                    </div>
                  </div>

                  <div className="invoice-terms pay-method-div">
                    <p className="heading">Select Payment Method</p>
                    <button
                      type="button"
                      className="payment-method-btn mb-2"
                      onClick={this.handleOnlinePayments}
                    >
                      <i className="fas fa-lock pr-1"></i> Online Payment{" "}
                      <i className="fas fa-angle-right"></i>
                    </button>
                    {/* <p className="text-center my-2 text-muted">Or</p> */}
                    <button
                      type="button"
                      className="payment-method-btn mb-2"
                      onClick={this.handleBankDeposits}
                    >
                      <i className="fas fa-wallet pr-1"></i> Bank
                      Deposit/Transfer <i className="fas fa-angle-right"></i>
                    </button>
                  </div>
                </div>

                <button
                  type="button"
                  className="btn-submit mt-3"
                  onClick={() => this.props.history.goBack()}
                  style={{
                    width: "auto",
                    float: "left",
                    background: "#efefef",
                  }}
                >
                  <i className="fas fa-angle-left"></i> Back
                </button>
              </form>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(PaymentMethodsCom);
