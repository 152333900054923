import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";

import Breadcrumb from "../breadcrumb/Breadcrumb";

import { isAuthenticated } from "../../../auth/auth";

function PaymentsCom() {
  const [pendings, setPendings] = useState([]);
  const [rejected, setRejected] = useState([]);
  const [payments, setPayments] = useState([]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/v1/agent_payments`, {
        headers: {
          Authorization: `Bearer ${isAuthenticated()}`,
        },
      })
      .then((res) => {
        // setLoading(false);
        setPayments(res.data);
      })
      .catch((err) => {
        console.log(err);
        // setLoading(false);
      });

    getBankTransfers();
  }, []);

  const getBankTransfers = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/v1/agent_payments/scanned`, {
        headers: {
          Authorization: `Bearer ${isAuthenticated()}`,
        },
      })
      .then((res) => {
        const onlyPendings = res.data.filter((el) => el.status === "SUBMITTED");
        const onlyRejected = res.data.filter((el) => el.status === "REJECTED");
        setPendings(onlyPendings);
        setRejected(onlyRejected);
      })
      .catch((err) => {
        console.log(err);
        // setLoading(false);
      });
  };

  return (
    <div className="content-col">
      <Breadcrumb title="Payments" />

      <div className="container-fluid inner-content py-4">
        <div className="ad-form-sec" style={{ maxWidth: "fit-content" }}>
          {pendings.length > 0 && (
            <>
              <p className="invoice-sec-heading">Pending Payments</p>
              <div
                className="content p-0 mb-4"
                style={{ maxWidth: "fit-content" }}
              >
                <table
                  className="table table-hover table-responsive  common-table border mb-0"
                  style={{ fontSize: 14 }}
                >
                  <thead className="header">
                    <tr>
                      <th className="border-0">Paid At</th>
                      {/* <th className="border-0">Invoice ID</th> */}
                      <th className="border-0">Status</th>
                      <th className="border-0">Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {pendings.length ? (
                      pendings.map((el) => (
                        <tr key={el.id}>
                          <td width="350">
                            {moment
                              .utc(el.createdAt)
                              .local()
                              .format("Do MMM YYYY")}
                            <p className="from-now">
                              {moment.utc(el.createdAt).local().fromNow()}
                            </p>
                          </td>
                          {/* <td width="130">
                        <p style={{ marginBottom: 0 }}>{el.invoiceId}</p>
                      </td> */}
                          <td width="100">
                            <p className="status pending">Pending</p>
                          </td>
                          <td>
                            <p style={{ marginBottom: 0 }}>Rs. {el.amount}</p>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="3" className="text-muted">
                          No Recods
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </>
          )}
          <p className="invoice-sec-heading">Rejected Payments</p>
          <div className="content p-0 mb-4" style={{ maxWidth: "fit-content" }}>
            <table
              className="table table-hover table-responsive  common-table border mb-0"
              style={{ fontSize: 14 }}
            >
              <thead className="header">
                <tr>
                  <th className="border-0">Paid At</th>
                  {/* <th className="border-0">Invoice ID</th> */}
                  <th className="border-0">Status</th>
                  <th className="border-0">Amount</th>
                </tr>
              </thead>
              <tbody>
                {rejected.length ? (
                  rejected.map((el) => (
                    <tr key={el.id}>
                      <td width="350">
                        {moment.utc(el.createdAt).local().format("Do MMM YYYY")}
                        <p className="from-now">
                          {moment.utc(el.createdAt).local().fromNow()}
                        </p>
                      </td>
                      {/* <td width="130">
                        <p style={{ marginBottom: 0 }}>{el.invoiceId}</p>
                      </td> */}
                      <td width="100">
                        <p className="status expired">Rejected</p>
                      </td>
                      <td>
                        <p style={{ marginBottom: 0 }}>Rs. {el.amount}</p>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="3" className="text-muted">
                      No Recods
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          <p className="invoice-sec-heading">All Approved Payments</p>
          <div className="content p-0">
            <table
              className="table table-hover table-responsive  common-table border mb-0"
              style={{ fontSize: 14 }}
            >
              <thead className="header">
                <tr>
                  <th className="border-0">Paid At</th>
                  <th className="border-0">Invoice ID</th>
                  <th className="border-0">Status</th>
                  <th className="border-0">Amount</th>
                </tr>
              </thead>
              <tbody>
                {payments.length ? (
                  payments.map((el, i) => (
                    <tr key={i}>
                      <td width="200">
                        {moment.utc(el.paidAt).local().format("Do MMM YYYY")}
                        <p className="from-now">
                          {moment.utc(el.paidAt).local().fromNow()}
                        </p>
                      </td>
                      <td width="150">
                        <p style={{ marginBottom: 0 }}>{el.invoiceId}</p>
                      </td>
                      <td width="100">
                        <p className="status live">Paid</p>
                      </td>
                      <td>
                        <p style={{ marginBottom: 0 }}>Rs. {el.amount}</p>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="3" className="text-muted">
                      No Recods
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PaymentsCom;
