import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import classnames from "classnames";

import Breadcrumb from "../breadcrumb/Breadcrumb";

import { isAuthenticated } from "../../../auth/auth";

class OnlinePaymentsCom extends Component {
  state = {
    allPayMethods: [],
    selected: "",
    sessionId: "",
    ipg: "",
    merchantId: "",
    firstName: "",
    orderId: "",
    items: "",
    hash: "",
    returnUrl: "",
    cancelUrl: "",
    notifyUrl: "",
    currency: "",
    actualDue: "",
    dueAmount: "",
    dueAmountErr: "",

    notSelectedError: "",

    loading: false,
  };

  componentDidMount = () => {
    let url_string = document.location.href;
    let url = new URL(url_string);
    let invoiceId = url.searchParams.get("invId");

    // invoice
    axios
      .get(`${process.env.REACT_APP_API_URL}/v1/agent_invoices/${invoiceId}`, {
        headers: {
          Authorization: `Bearer ${isAuthenticated()}`,
        },
      })
      .then((res) => {
        axios
          .get(`${process.env.REACT_APP_API_URL}/v1/agent_payments`, {
            headers: {
              Authorization: `Bearer ${isAuthenticated()}`,
            },
          })
          .then((result) => {
            const filterByInvId = result.data.filter(
              (el) => el.invoiceId === res.data.id
            );

            if (filterByInvId.length > 0) {
              this.setState({
                actualDue:
                  res.data.amount +
                  res.data.carriedForwardAmount -
                  filterByInvId[0].amount,
                dueAmount: sessionStorage.getItem("payment_amt")
                  ? sessionStorage.getItem("payment_amt")
                  : res.data.amount +
                    res.data.carriedForwardAmount -
                    filterByInvId[0].amount,
              });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
        // setLoading(false);
      });

    // paymentmethods
    axios
      .get(`${process.env.REACT_APP_API_URL}/v1/generic/payment_methods`)
      .then((res) => {
        this.setState({ allPayMethods: res.data });
      })
      .catch((err) => {
        console.log(err);

        // this.setState({ preLoading: false });
      });

    this.loadScripts();
  };

  handleChange = (e) => {
    this.setState({
      dueAmount: e.target.value,
      dueAmountErr: "",
    });
  };

  loadScripts = () => {
    // stripe
    const stripeScript = document.createElement("script");

    stripeScript.src = "https://js.stripe.com/v3/";
    stripeScript.async = true;

    document.body.appendChild(stripeScript);
  };

  redirect = () => {
    var stripe = window.Stripe(
      process.env.REACT_APP_API_URL === "https://api-dev.poruwa.lk"
        ? "pk_test_nm5KcU9wsFKxBXWgEamxOwYF00DSQTi1lK"
        : "pk_live_8q8kKbsf3TyrjHkea7kXT4rb00WXhHJaah"
    );

    this.setState({ loadingCard: true });

    if (this.state.ipg === "STRIPE") {
      stripe
        .redirectToCheckout({
          // Make the id field from the Checkout Session creation API response
          // available to this file, so you can provide it as parameter here
          // instead of the {{CHECKOUT_SESSION_ID}} placeholder.
          sessionId: this.state.sessionId,
        })
        .then(function (result) {
          this.setState({ loadingCard: false });
          // If `redirectToCheckout` fails due to a browser or network
          // error, display the localized error message to your customer
        });
    } else {
      document.getElementById("pay-by-genie").click();

      this.setState({ dissableCard: true });
    }
  };

  handleCheckout = () => {
    this.setState({ loading: true });

    console.log(this.state.selected);

    // if (
    //   this.state.dueAmount >= 200 &&
    //   this.state.dueAmount <= this.state.actualDue
    // ) {
    if (this.state.selected) {
      let data = `paymentMethodId=${this.state.selected}&amount=${this.state.dueAmount}`;

      axios
        .post(`${process.env.REACT_APP_API_URL}/v1/agent_payments`, data, {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${isAuthenticated()}`,
          },
        })
        .then((res) => {
          localStorage.setItem("session_creation", JSON.stringify(res.data));

          this.setState(
            {
              sessionId: res.data.sessionId,
              ipg: res.data.ipg,
              merchantId:
                res.data.ipg === "PAYHERE"
                  ? res.data.payhereParams.merchantId
                  : "",
              firstName:
                res.data.ipg === "PAYHERE"
                  ? res.data.payhereParams.firstName
                  : "",
              orderId:
                res.data.ipg === "PAYHERE"
                  ? res.data.payhereParams.orderId
                  : "",
              items:
                res.data.ipg === "PAYHERE" ? res.data.payhereParams.items : "",
              hash:
                res.data.ipg === "PAYHERE" ? res.data.payhereParams.hash : "",
              returnUrl:
                res.data.ipg === "PAYHERE"
                  ? res.data.payhereParams.returnUrl
                  : "",
              cancelUrl:
                res.data.ipg === "PAYHERE"
                  ? res.data.payhereParams.cancelUrl
                  : "",
              notifyUrl:
                res.data.ipg === "PAYHERE"
                  ? res.data.payhereParams.notifyUrl
                  : "",
              currency:
                res.data.ipg === "PAYHERE"
                  ? res.data.payhereParams.currency
                  : "",
              dueAmount:
                res.data.ipg === "PAYHERE" ? res.data.payhereParams.amount : "",
            },
            () => {
              this.redirect();
            }
          );
        })
        .catch((err) => {
          console.log(err);
          this.setState({ loading: false });
        });
    } else {
      this.setState({
        notSelectedError: "Please select a payment method",
        loading: false,
      });
    }
    // } else {
    //   this.setState({
    //     dueAmountErr: "Invalid payment amount",
    //     loading: false,
    //   });
    // }
  };

  render() {
    const payMethods = this.state.allPayMethods
      .filter((el) => el.isBank === false)
      .sort((a, b) => a.sortOrder - b.sortOrder);
    return (
      <>
        <div className="content-col">
          <Breadcrumb title="Checkout" />

          <div className="container-fluid inner-content">
            <div className="ad-form-sec">
              <form noValidate>
                <div className="content" style={{ paddingTop: 10 }}>
                  <div className="invoice-terms pay-method-div">
                    <p
                      className="text-center font-weight-bold mt-3 mb-4"
                      style={{ background: "#e7e7e7", padding: "10px" }}
                    >
                      Amount : Rs. {sessionStorage.getItem("payment_amt")}
                    </p>
                    <p className="heading my-2">Select Payment Method</p>

                    {/* <p className="sub-heading">Online Payment Methods</p> */}
                    <p className="desc text-center mb-3">
                      You will be re-directed to the relevant payment gateway on
                      the next screen.
                    </p>

                    {payMethods
                      .filter((el) => el.id !== "paypal")
                      .map((el, i) => (
                        <div
                          className={
                            this.state.selected === el.id
                              ? "method method-active"
                              : "method"
                          }
                          key={i}
                          onClick={() =>
                            this.setState({
                              selected: el.id,
                              notSelectedError: "",
                            })
                          }
                        >
                          <div>
                            <img src={el.icon} alt={el.id} />
                          </div>
                          <div>
                            <p>{el.name}</p>
                          </div>

                          {this.state.selected === el.id && (
                            <i className="fas fa-check method-select-icon"></i>
                          )}
                        </div>
                      ))}

                    {this.state.notSelectedError && (
                      <p
                        style={{
                          fontSize: 12,
                          color: "#f44336",
                          marginLeft: "1.19%",
                          marginTop: "5px",
                          marginBottom: "15px",
                          texAlign: "center",
                        }}
                      >
                        * {this.state.notSelectedError}
                      </p>
                    )}
                  </div>
                  <form
                    id="ext-merchant-frm"
                    action={
                      process.env.REACT_APP_API_URL ===
                      "https://api-dev.poruwa.lk"
                        ? "https://sandbox.payhere.lk/pay/checkout"
                        : "https://www.payhere.lk/pay/checkout"
                    }
                    method="post"
                    acceptCharset="UTF-8"
                    encType="application/x-www-form-urlencoded"
                  >
                    <input
                      type="hidden"
                      name="merchant_id"
                      value={this.state.merchantId}
                    />
                    <input
                      type="hidden"
                      name="return_url"
                      value={this.state.returnUrl}
                    />
                    <input
                      type="hidden"
                      name="cancel_url"
                      value={this.state.cancelUrl}
                    />
                    <input
                      type="hidden"
                      name="notify_url"
                      value={this.state.notifyUrl}
                    />

                    <input
                      type="hidden"
                      name="first_name"
                      value={this.state.firstName}
                    />
                    <input type="hidden" name="last_name" value="" />
                    <input type="hidden" name="email" value="" />
                    <input type="hidden" name="phone" value="" />
                    <input type="hidden" name="address" value="" />
                    <input type="hidden" name="city" value="" />
                    <input type="hidden" name="country" value="" />

                    <input type="hidden" name="hash" value={this.state.hash} />
                    <input
                      type="hidden"
                      name="order_id"
                      value={this.state.orderId}
                    />
                    <input
                      type="hidden"
                      name="items"
                      value={this.state.items}
                    />
                    <input
                      type="hidden"
                      name="currency"
                      value={this.state.currency}
                    />
                    <input
                      type="hidden"
                      name="amount"
                      value={this.state.dueAmount}
                    />
                    <button id="pay-by-genie" hidden>
                      Submit
                    </button>
                  </form>
                </div>

                <button
                  type="button"
                  className="btn-submit mt-3"
                  onClick={() => this.props.history.goBack()}
                  style={{
                    width: "auto",
                    float: "left",
                    background: "#efefef",
                  }}
                >
                  <i className="fas fa-angle-left"></i> Back
                </button>

                <button
                  type="button"
                  className="btn-submit mt-3"
                  onClick={this.handleCheckout}
                  style={{ float: "right", width: 195 }}
                >
                  {this.state.loading ? (
                    <div
                      className="spinner-border spinner-border-sm"
                      role="status"
                    >
                      <span className="sr-only">Loading...</span>
                    </div>
                  ) : (
                    <span>
                      Continue <i className="fas fa-angle-right"></i>
                    </span>
                  )}
                </button>
              </form>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(OnlinePaymentsCom);
