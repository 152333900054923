import React from "react";

import Close from "../../../images/close-white.png";

function SubmitConfirmModal(props) {
  return (
    <div
      className="modal fade"
      id="submitForm"
      tabIndex="-1"
      aria-labelledby="submitFormLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <p className="modal-title" id="submitFormLabel">
              Confirmation
            </p>
            <img
              src={Close}
              alt="close"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              width="50px"
              style={{ cursor: "pointer" }}
              id="submitFormClose"
            />
          </div>
          <div className="modal-body">
            <p>Do you want to submit this Ad?</p>
          </div>
          <div className="modal-footer border-0 p-2">
            <button type="button" className="btn " data-dismiss="modal">
              Cancel
            </button>
            <button
              type="button"
              className="btn-submit mt-0"
              onClick={props.handleSubmit}
              style={{ float: "right", width: 90 }}
            >
              {props.loading ? (
                <div class="spinner-border spinner-border-sm" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              ) : (
                "Confirm"
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SubmitConfirmModal;
