import React from "react";

import Close from "../../../images/close.png";

function RenewModal(props) {
  return (
    <div
      className="modal fade"
      id="renewModal"
      tabIndex="-1"
      aria-labelledby="renewModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header border-0 pb-0">
            <p className="modal-title" id="renewModalLabel">
              Are you sure?
            </p>
            {/* <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button> */}

            <img
              src={Close}
              alt="close"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              width="50px"
              style={{ cursor: "pointer" }}
              id="renewModalClose"
            />
          </div>
          <div className="modal-body">
            <p>Do you want to renew this ad?</p>
          </div>
          <div className="modal-footer border-0 p-2">
            <button type="button" className="btn" data-dismiss="modal">
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-success"
              onClick={() => props.handleRenew(props.renewPostId)}
              style={{ width: 75 }}
            >
              {props.loading ? (
                <div class="spinner-border spinner-border-sm" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              ) : (
                "Renew"
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RenewModal;
