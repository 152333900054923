import React from "react";

function PhotosUpload(props) {
  return (
    <div className="form-row m-0">
      <div className="form-group col px-0 mb-0">
        {props.images.length > 0 &&
          props.images.map((el, i) => (
            <div className="prev-images-div" key={i}>
              <img
                src={URL.createObjectURL(el)}
                className="prev-images"
                alt="images"
              />
              <i
                className="fas fa-times-circle delete-img-icon"
                onClick={() => props.delImages(i)}
              ></i>
            </div>
          ))}

        <input
          type="file"
          id="imgUpload"
          className="imgUpload"
          name="photo"
          onChange={props.handleUpload}
          accept="image/x-png,image/jpeg,image/jpg"
        />

        <label className="img-upload-label" htmlFor="imgUpload">
          {props.imgLoading ? (
            <div class="spinner-border spinner-border-sm" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          ) : (
            <i className="fas fa-plus add-img-icon"></i>
          )}
        </label>
      </div>
    </div>
  );
}

export default PhotosUpload;
