import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import Pagination from "@material-ui/lab/Pagination";

import Breadcrumb from "../breadcrumb/Breadcrumb";

import { isAuthenticated } from "../../../auth/auth";
import RenewModal from "../modals/RenewModal";
import FormPhotosModal from "../modals/FormPhotosModal";

function ManageAllAds() {
  const [ads, setAds] = useState([]);
  const [page, setPage] = useState(1);
  const [images, setImages] = useState([]);
  const [scanId, setScanId] = useState("");
  const [search, setSearch] = useState("");
  const [renewPostId, setRenewPostId] = useState("");
  const [loading, setLoading] = useState("");

  const itemsPerPage = 20;

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/v1/agent_posts/scanned`, {
        headers: {
          Authorization: `Bearer ${isAuthenticated()}`,
        },
      })
      .then((res) => {
        // setLoading(false);

        const sortByDate = res.data.sort((a, b) =>
          b.createdAt.localeCompare(a.createdAt)
        );

        setAds(sortByDate);
      })
      .catch((err) => {
        console.log(err);
        // setLoading(false);
      });
  }, []);

  const onChange = (e) => {
    setSearch(e.target.value);
  };

  const handlePhotos = (images, id) => {
    setImages(images);
    setScanId(id);
  };

  const handlePagination = (event, value) => {
    // scroll to top
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;

    setPage(value);
  };

  const handleRenew = (id) => {
    setLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/v1/agent_posts/${id}/renew`,
        null,
        {
          headers: {
            Authorization: `Bearer ${isAuthenticated()}`,
          },
        }
      )
      .then((res) => {
        setLoading(false);
        document.getElementById("renewModalClose").click();
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const filterBySearch = ads.filter((el) => {
    if (search) {
      return (
        (el.post &&
          el.post.id.toLowerCase().indexOf(search.toLowerCase()) !== -1) ||
        (el.post &&
          el.post.personalInfo.fname
            .toLowerCase()
            .indexOf(search.toLowerCase()) !== -1) ||
        (el.postPhoneNumber &&
          el.postPhoneNumber.toLowerCase().indexOf(search.toLowerCase()) !== -1)
      );
    } else {
      return el;
    }
  });

  // pagination
  const noOfPages = Math.ceil(filterBySearch.length / itemsPerPage);
  const indexOfLast = page * itemsPerPage;
  const indexOfFirst = indexOfLast - itemsPerPage;
  const currentItems = filterBySearch.slice(indexOfFirst, indexOfLast);

  return (
    <div className="content-col">
      <Breadcrumb title="All Ads" />

      <div className="row m-0">
        <div className="col p-0">
          <form
            style={{
              display: "block",
              width: "fit-content",
              margin: "auto",
              marginTop: 40,
              // marginBottom: 30,
            }}
          >
            <div className="form-row align-items-center">
              <div className="col-auto">
                <label className="sr-only" htmlFor="search-by-id">
                  Search by Ad ID or phone number
                </label>
                <div className="input-group mb-0" style={{ width: "280px" }}>
                  <input
                    type="text"
                    className="form-control pr-5"
                    id="search-by-id"
                    placeholder="Ad ID / name / phone number"
                    value={search}
                    onChange={onChange}
                    autoComplete="off"
                    style={{ borderRadius: "5px" }}
                  />
                  <i
                    className="fas fa-search"
                    style={{
                      position: "absolute",
                      top: "12px",
                      right: "8px",
                      color: "#949494",
                      zIndex: 3,
                    }}
                  ></i>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div className="container-fluid inner-content py-4">
        <div className="ad-form-sec" style={{ maxWidth: "fit-content" }}>
          <div className="content p-0">
            <table
              className="table table-hover table-responsive common-table border mb-0"
              style={{ fontSize: 14 }}
            >
              <thead className="header">
                <tr>
                  <th className="border-0">Submited At</th>
                  <th className="border-0">Ad Created At</th>
                  <th className="border-0">Ad ID</th>
                  <th className="border-0">Name</th>
                  <th className="border-0">Phone</th>
                  <th className="border-0">Status</th>
                  <th className="border-0">Application</th>
                  <th className="border-0">Action</th>
                </tr>
              </thead>
              <tbody>
                {currentItems.length ? (
                  currentItems.map((el) => (
                    <tr key={el.id}>
                      <td width="130">
                        {moment.utc(el.createdAt).local().format("Do MMM YYYY")}
                        <p className="from-now">
                          {moment.utc(el.createdAt).local().fromNow()}
                        </p>
                      </td>
                      <td width="130">
                        {el.post
                          ? moment
                              .utc(el.post && el.post.postPublishedAt)
                              .local()
                              .format("Do MMM YYYY")
                          : "-"}
                      </td>
                      <td width="130">
                        <p style={{ marginBottom: 0 }}>
                          {el.post ? el.post.id : "-"}
                        </p>
                      </td>
                      <td width="130">
                        <p style={{ marginBottom: 0 }}>
                          {el.post ? el.post.personalInfo.fname : "-"}
                        </p>
                      </td>
                      <td width="130">
                        <p style={{ marginBottom: 0 }}>
                          {el.postPhoneNumber ? el.postPhoneNumber : "-"}
                        </p>
                      </td>
                      <td width="130">
                        {el.post && el.post.status === "LIVE" ? (
                          <p className="status live">Live</p>
                        ) : el.post && el.post.status === "IN_REVIEW" ? (
                          <p className="status reviewing">Reviewing</p>
                        ) : el.post && el.post.status === "DISABLED" ? (
                          <p className="status disable">Disable</p>
                        ) : el.post && el.post.status === "EXPIRED" ? (
                          <p className="status expired">Expired</p>
                        ) : (
                          <p className="status pending">Pending</p>
                        )}
                      </td>
                      <td width="90">
                        <p
                          className="mb-0 text-primary"
                          data-toggle="modal"
                          data-target="#formPhotosModal"
                          onClick={() => handlePhotos(el.images, el.id)}
                          style={{ cursor: "pointer" }}
                        >
                          {el.images.length} Captures
                        </p>
                      </td>
                      <td align="center">
                        {((el.post && el.post.status === "LIVE") ||
                          (el.post && el.post.status === "EXPIRED")) &&
                        moment
                          .utc(
                            moment.utc(el.post.expiresAt).subtract(7, "days")
                          )
                          .local()
                          .format() < moment().format() ? (
                          <button
                            className="btn btn-success btn-sm"
                            data-toggle="modal"
                            data-target="#renewModal"
                            onClick={() => setRenewPostId(el.post.id)}
                          >
                            Renew
                          </button>
                        ) : (
                          "-"
                        )}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="3" className="text-muted">
                      No Recods
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>

        <div className="row m-0">
          <div className="col p-0">
            <div className="pagination-div">
              {noOfPages > 1 && (
                <Pagination
                  count={noOfPages}
                  page={page}
                  onChange={handlePagination}
                  className="pagi"
                />
              )}
            </div>
          </div>
        </div>

        <FormPhotosModal images={images} scanId={scanId} />

        <RenewModal
          renewPostId={renewPostId}
          handleRenew={handleRenew}
          loading={loading}
        />
      </div>
    </div>
  );
}

export default ManageAllAds;
