import React, { useState } from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";
import axios from "axios";
import qs from "qs";

import LogoCol from "../login/LogoCol";

const initial = {
  name: "",
  email: "",
  password: "",
  conPassword: "",
  nameErr: "",
  passwordErr: "",
  conPasswordErr: "",
  authErr: "",
};

function CommonReg() {
  const [form, setForm] = useState(initial);

  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
      [[e.target.name] + "Err"]: "",
    });
  };

  // validate
  const validate = () => {
    let nameErr = "";
    let emailErr = "";
    let passwordErr = "";
    let conPasswordErr = "";

    if (!form.name) {
      nameErr = "Name is required";
    }

    if (!form.email) {
      emailErr = "Email is required";
    } else if (
      !form.email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)
    ) {
      emailErr = "Email is invalid";
    }

    if (!form.password) {
      passwordErr = "Password is required";
    } else if (form.password.length < 8 || form.password.length > 20) {
      passwordErr = "Password must be between 8 to 20 characters long";
    }
    //  else if (
    //   !form.password.match(
    //     /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
    //   )
    // ) {
    //   passwordErr =
    //     "Password must contain at least one uppercase letter, one lowercase letter, one number and one special character";
    // }

    if (!form.conPassword) {
      conPasswordErr = "Confirm password is required";
    } else if (form.password !== form.conPassword) {
      conPasswordErr = "Passwords should match";
    }

    if (nameErr || emailErr || passwordErr || conPasswordErr) {
      setForm({
        ...form,
        nameErr,
        emailErr,
        passwordErr,
        conPasswordErr,
      });

      return false;
    }

    return true;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      setLoading(true);

      const data = qs.stringify({
        userId: form.email,
        name: form.name,
        password: form.password,
        idType: "EMAIL",
        refCode: null,
        owner: "SELF",
      });

      axios
        .post(`${process.env.REACT_APP_API_URL}/v1/auth/register`, data, {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        })
        .then((res) => {
          setForm(initial);
          setLoading(false);
        })
        .catch((err) => {
          if (err.response.data.code === 1100) {
            setForm({ ...form, emailErr: "Email already exists" });
          } else {
            setForm({ ...form, authErr: "Error!" });
          }
          setLoading(false);
        });
    }
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <LogoCol />
        <div className="col login-reg-col">
          <div className="login-reg-container">
            <p className="heading">Create an Account</p>
            <p style={{ fontSize: 14, color: "#dc3545" }}>{form.authErr}</p>
            <form noValidate>
              <div className="form-group p-0">
                <label htmlFor="name">Name</label>
                <input
                  type="name"
                  className={classnames("form-control", {
                    "is-invalid": form.nameErr,
                  })}
                  id="name"
                  name="name"
                  value={form.name}
                  onChange={handleChange}
                />
                <div className="invalid-feedback">{form.nameErr}</div>
              </div>
              <div className="form-group p-0">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  className={classnames("form-control", {
                    "is-invalid": form.emailErr,
                  })}
                  id="email"
                  name="email"
                  onChange={handleChange}
                  value={form.email}
                  placeholder="example@example.com"
                />
                <div className="invalid-feedback">{form.emailErr}</div>
              </div>
              <div className="form-group p-0">
                <label htmlFor="password">Password</label>
                <input
                  type="password"
                  className={classnames("form-control", {
                    "is-invalid": form.passwordErr,
                  })}
                  id="password"
                  name="password"
                  value={form.password}
                  onChange={handleChange}
                />
                <div className="invalid-feedback">{form.passwordErr}</div>
              </div>
              <div className="form-group p-0 mb-0">
                <label htmlFor="conPassword">Confirm Password</label>
                <input
                  type="password"
                  className={classnames("form-control", {
                    "is-invalid": form.conPasswordErr,
                  })}
                  id="conPassword"
                  name="conPassword"
                  value={form.conPassword}
                  onChange={handleChange}
                />
                <div className="invalid-feedback">{form.conPasswordErr}</div>
              </div>
              {/* <div className="form-group p-0 mb-0">
                <label htmlFor="conPassword">Confirm Password</label>
                <input
                  type="password"
                  className={classnames("form-control", {
                    "is-invalid": form.conPasswordErr,
                  })}
                  id="conPassword"
                  name="conPassword"
                  value={form.conPassword}
                  onChange={handleChange}
                />
                <div className="invalid-feedback">{form.conPasswordErr}</div>
              </div> */}

              <button
                type="submit"
                className="btn-submit mt-4"
                onClick={handleSubmit}
              >
                {loading ? (
                  <div
                    className="spinner-border spinner-border-sm text-light"
                    role="status"
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : null}
                {loading ? "" : "Create Now"}
              </button>
            </form>
            <p className="mt-3 form-bellow-link">
              Already you have an account?{" "}
              <Link to="/" className="login-now-link">
                Login
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CommonReg;
